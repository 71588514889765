import React, { useEffect, useState } from "react";
import SettingsItems from "./settingsItems";
import HowToPlayModal from "./howToPlayModal";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import gameIcon from "../../media/eyeq-icon.svg";
// import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../contexts/authContext";
import { doSignOut } from "../../firebase/auth";
// import { Timestamp } from "@firebase/firestore";
import { getLatestDocumentByCurrentUser } from "../../firebase/store";

const HomePage = ({ allowAccessToPlay }) => {
  const { userLoggedIn } = useAuth();
  const [showSettings, setShowSettings] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [userLogIn, setIsUserLogIn] = useState(false);
  const [result, setResult] = useState();
  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  // const isDesktop = useMediaQuery({ minWidth: 1024 });

  let navigate = useNavigate();

  useEffect(() => {
    const fetchLatestDocument = async () => {
      const doc = await getLatestDocumentByCurrentUser();

      if (!doc) {
        console.log("Nothing found");
        localStorage.clear();
        return;
      }

      const createdAt = doc.created_at.toDate(); // assuming created_at is a Firestore timestamp
      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0);

      const startOfYesterday = new Date(startOfToday);
      startOfYesterday.setDate(startOfYesterday.getDate() - 1);

      const endOfYesterday = new Date(startOfToday);
      endOfYesterday.setMilliseconds(-1);

      localStorage.clear();

      localStorage.setItem("gamesPlayed", doc.gamesPlayed);

      if (createdAt >= startOfToday) {
        console.log("It's from today, let's update and continue as is");
        localStorage.setItem("UserGamePlay", JSON.stringify(doc));
        setResult(doc);
      } else if (
        createdAt >= startOfYesterday &&
        createdAt <= endOfYesterday &&
        doc.status === "COMPLETED"
      ) {
        console.log(
          "Document is from yesterday and completed, updating streak"
        );
        localStorage.setItem("currentStreak", doc.currentStreak);
      } else {
        console.log("Old data");
        setResult(undefined);
      }
    };

    if (userLoggedIn) {
      fetchLatestDocument().catch(console.error);
      // setIsUserLogIn(true);
    } else {
      // setIsUserLogIn(false);
      let UserObject = localStorage.getItem("UserGamePlay");
      UserObject = JSON.parse(UserObject);

      const createdAt = new Date(parseInt(UserObject?.created_at));
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);

      if (createdAt < startOfDay) {
        console.log("Old data");
        localStorage.clear();
        setResult(undefined);
      } else {
        console.log("It's from today, let's update and continue as is");
        setResult(UserObject);
      }
      if (UserObject) {
        localStorage.setItem("gamesPlayed", UserObject?.gamesPlayed);
      }
    }
  }, [userLoggedIn]);

  const handleNavigate = (path) => {
    console.log("Allowing access to play");
    allowAccessToPlay();
    navigate(path);
  };

  // const insertUserEntry = async () => {
  //   try {
  //     let newEntryNow = {
  //       puzzle_id: "202331",
  //       status: "COMPLETED",
  //       currentQuestionIndex: 10,
  //       currentScore: 10,
  //       gameState: [
  //         ["11:00 am", "10:12 pm"],
  //         ["Grade 12", "Sophomores", "Grade 8"],
  //         ["July 1", "July 22"],
  //       ],
  //       hasSeenPicture: true,
  //       AvgScorePercentage: 10,
  //       currentStreak: 10,
  //       gamesPlayed: 10,
  //       isOnStreak: false,
  //       lastCompleted: "2020-01-20T08:00:00.000Z",
  //       maxStreak: 10,
  //       created_at: Timestamp.fromDate(new Date()),
  //     };

  //     await createNewUserEntry(newEntryNow);

  //     console.log("User entry created successfully:", result);
  //     return result;
  //   } catch (error) {
  //     console.error("Error creating user entry:", error);
  //     throw new Error("Error creating user entry");
  //   }
  // };

  // const testAPI = () => {
  //   insertUserEntry()
  //     .then((response) => {
  //       console.log("Insert response:", response);
  //     })
  //     .catch((error) => {
  //       console.error("Insert error:", error);
  //     });
  // };

  return (
    <div className="bg-[#E3E3E1]">
      <div className="home-main-div">
        <IoMdSettings
          onClick={() => setShowSettings(!showSettings)}
          className={`settings-icon ${
            showSettings ? "rotate-icon-click" : "rotate-icon"
          }`}
        />

        {showSettings && <SettingsItems {...{ setShowSettings }} />}

        <div className="flex-container">
          <div className="logo-container">
            <img className="logo-image" src={gameIcon} alt="logo of quiz app" />
          </div>

          <h1 className="header">Fragments of Memory</h1>

          <p className="paragraph">
            Memorize and guess the right answers! You get 3 chances!
          </p>

          <div className="button-container">
            {result?.status === "COMPLETED" ? (
              ""
            ) : (
              <button onClick={() => setIsModalOpen(true)} className="button">
                How To Play
              </button>
            )}
            {userLoggedIn ? (
              <button
                className="button"
                onClick={() => {
                  doSignOut().then(() => {
                    // navigate("/");
                    // setIsUserLogIn(false);
                    // localStorage.setItem("userLogIn", false);
                    localStorage.clear();
                    window.location.reload();
                  });
                }}
              >
                Log Out
              </button>
            ) : (
              <button
                className="button"
                onClick={() => handleNavigate("/login")}
              >
                Log In
              </button>
            )}
            <button
              className="button special-button"
              onClick={() => handleNavigate("/play")}
            >
              {result?.status === "COMPLETED" ? "See Stats" : "Play"}
            </button>
          </div>

          <p className="date-display">
            {new Date().toLocaleDateString("en-US", dateOptions)}
          </p>

          <div class="cookie">
            This site uses cookies to deliver and enhance the quality of its
            services and to analyse traffic.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://policies.google.com/technologies/cookies?hl=en"
              aria-label="Learn more about Google's cookie policy. Opens in a new browser tab"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
      <HowToPlayModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default HomePage;
